import React from "react";
import {
  SiteNavigationElementSchema,
  FoodEstablishmentSchema,
  RecaptchaProvider,
  TimedElement,
  Find,
  NavigationV2,
  HookComponentProvider,
  HoursOfOperation,
  LocationsContext,
  Background,
  EmailCapture,
  Location,
  ExternalLinkMap,
  Phone,
  Email,
  SiteMap,
  CopyrightLabel,
  OutboundLink,
  FishermanBanner,
} from "@bluefin/components";
import { Message, Header, Grid } from "semantic-ui-react";
import { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  filterRegularLinks,
  filterButtonLinks,
} from "../utils/utils";
import "../semantic/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            url
            pageType
            title
            navigationPriority
            groupName
            props
          }
        }
        fishermanBusiness {
          name
          type
          logo
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          primaryLocation {
            timezone
          }
          gatsbyLogo {
            childImageSharp {
              gatsbyImageData(layout: FIXED, height: 60)
            }
          }
          _id
          brandIdentity
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
              gatsbyFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
        fishermanWebsiteComponent(
          layout: { name: { eq: "@" } }
          fastId: { eq: "Announcement" }
        ) {
          data {
            referenceId
          }
        }
        announcementComponent: fishermanWebsiteComponent(
          layout: { name: { eq: "@" } }
          fastId: { eq: "Announcement" }
        ) {
          data {
            bannerBackgroundColor
          }
          contentNodes {
            _id
            content
            contentType
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
        fishermanWebsiteComponent,
        announcementComponent,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.aronafoodplace.com"}
          />
          <FoodEstablishmentSchema
            name={fishermanBusiness.name}
            siteUrl={"https://www.aronafoodplace.com"}
            businessType={fishermanBusiness.type}
            logo={fishermanBusiness.logo}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <React.Fragment>
              <RecaptchaProvider>
                <TimedElement
                  referenceId={fishermanWebsiteComponent.data.referenceId}
                  timezone={fishermanBusiness.primaryLocation.timezone}
                >
                  <Message
                    className={"announcement-banner"}
                    style={{
                      backgroundColor:
                        announcementComponent.data.bannerBackgroundColor,
                    }}
                  >
                    <Find
                      collection={announcementComponent.contentNodes}
                      predicate={{ contentType: "TEXT" }}
                      targetFields={["content"]}
                    />
                  </Message>
                </TimedElement>
              </RecaptchaProvider>
              <NavigationV2
                sticky={true}
                fullScreen={true}
                desktop={
                  <NavigationV2.Container>
                    <NavigationV2.Menu className={"internal-links"}>
                      <NavigationV2.Item>
                        <NavigationV2.Links
                          links={filterRegularLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                      </NavigationV2.Item>
                    </NavigationV2.Menu>
                    <NavigationV2.Menu className={"logo"}>
                      <NavigationV2.Logo
                        title={
                          <Header as={"h3"}>{fishermanBusiness.name}</Header>
                        }
                        src={fishermanBusiness.gatsbyLogo.childImageSharp}
                      />
                    </NavigationV2.Menu>
                    <NavigationV2.Menu className={"external-links"}>
                      <NavigationV2.Item className={"hours"}>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <HoursOfOperation
                              displayOption={"dailyWithPopup"}
                              displayOpenClosedStatus={true}
                            />
                          }
                          hookToPropsMap={{
                            hours: "activeLocation.hours",
                            timezone: "activeLocation.timezone",
                          }}
                          hookParams={[LocationsContext]}
                          onlyRenderOnClientSide={true}
                        />
                      </NavigationV2.Item>
                      <NavigationV2.Item>
                        <NavigationV2.Links
                          dropdownDirection={"left"}
                          links={filterButtonLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                      </NavigationV2.Item>
                    </NavigationV2.Menu>
                  </NavigationV2.Container>
                }
                mobile={
                  <React.Fragment>
                    <NavigationV2.Container className={"mobile-header"}>
                      <NavigationV2.Toggle />
                      <NavigationV2.Menu className={"logo"}>
                        <NavigationV2.Logo
                          title={
                            <Header as={"h3"}>{fishermanBusiness.name}</Header>
                          }
                          src={fishermanBusiness.gatsbyLogo.childImageSharp}
                        />
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                    <NavigationV2.Container tray={true} vertical={true}>
                      <NavigationV2.Menu className={"internal-links"}>
                        <NavigationV2.Item>
                          <NavigationV2.Links
                            links={filterRegularLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            })}
                          />
                        </NavigationV2.Item>
                      </NavigationV2.Menu>
                      <NavigationV2.Menu className={"external-links"}>
                        <NavigationV2.Item className={"hours"}>
                          <HookComponentProvider
                            hook={useContext}
                            component={
                              <HoursOfOperation
                                displayOption={"dailyWithPopup"}
                                displayOpenClosedStatus={true}
                              />
                            }
                            hookToPropsMap={{
                              hours: "activeLocation.hours",
                              timezone: "activeLocation.timezone",
                            }}
                            hookParams={[LocationsContext]}
                            onlyRenderOnClientSide={true}
                          />
                        </NavigationV2.Item>
                        <NavigationV2.Item>
                          <NavigationV2.Links
                            links={filterButtonLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            })}
                          />
                        </NavigationV2.Item>
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                  </React.Fragment>
                }
              />
            </React.Fragment>
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
              <footer>
                <Background columns={1} className={"texture-background"}>
                  <Background.Pattern type={"CIRCLE"}>
                    <Grid stackable={true}>
                      <Grid.Row>
                        <Grid stackable={true}>
                          <Grid.Row columns={3}>
                            <Grid.Column className={"email-capture-column"}>
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <EmailCapture
                                    businessId={fishermanBusiness._id}
                                    businessType={fishermanBusiness.type}
                                    type={"footer"}
                                  />
                                }
                                hookToPropsMap={{
                                  locationId: "activeLocation.id",
                                }}
                                hookParams={[LocationsContext]}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid stackable={true}>
                          <Grid.Row columns={3}>
                            <Grid.Column className={"address-column"}>
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <Location businessName={"Address"} />
                                }
                                hookToPropsMap={{
                                  "address.street": "activeLocation.street",
                                  "address.city": "activeLocation.city",
                                  "address.state": "activeLocation.state",
                                  "address.zip": "activeLocation.zipCode",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                              <HookComponentProvider
                                hook={useContext}
                                component={<ExternalLinkMap />}
                                hookToPropsMap={{
                                  "address.street": "activeLocation.street",
                                  "address.city": "activeLocation.city",
                                  "address.state": "activeLocation.state",
                                  "address.zip": "activeLocation.zipCode",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                            </Grid.Column>
                            <Grid.Column className={"contact-us-column"}>
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <div>
                                    <strong>Contact us</strong>
                                  </div>
                                }
                                hookToPropsMap={{
                                  phone: "activeLocation.phoneNumber",
                                  email: "activeLocation.email",
                                }}
                                requiredProps={[["phone", "email"]]}
                                hookParams={[LocationsContext]}
                              />
                              <HookComponentProvider
                                hook={useContext}
                                component={Phone}
                                hookToPropsMap={{
                                  phone: "activeLocation.phoneNumber",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                              <div>
                                <Phone phone={"(214) 501-4514"} />
                              </div>
                              <HookComponentProvider
                                hook={useContext}
                                component={Email}
                                hookToPropsMap={{
                                  email: "activeLocation.email",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                            </Grid.Column>
                            <Grid.Column
                              className={"hours-of-operation-column"}
                            >
                              <HookComponentProvider
                                hook={useContext}
                                component={
                                  <HoursOfOperation
                                    displayOption={"grouped"}
                                    header={<strong>Hours of Operation</strong>}
                                  />
                                }
                                hookToPropsMap={{
                                  hours: "activeLocation.hours",
                                }}
                                hookParams={[LocationsContext]}
                                onlyRenderOnClientSide={true}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column textAlign={"center"}>
                          <SiteMap
                            links={allFishermanBusinessWebsitePage.nodes}
                            horizontal={true}
                            bulleted={false}
                          />
                          <CopyrightLabel
                            company={fishermanBusiness.name}
                            phrase={"All Rights Reserved"}
                            privacyPolicyLink={
                              <OutboundLink
                                to={
                                  "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                                }
                                label={"Privacy Policy"}
                                eventLabel={"copyright-privacy-policy"}
                              />
                            }
                            brand={fishermanBusiness.brandIdentity}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Background.Pattern>
                </Background>
              </footer>
            </RecaptchaProvider>
          </div>
          <FishermanBanner />
        </React.Fragment>
      );
    }}
  />
);
